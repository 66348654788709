import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LeaveListData } from './lib'
import {
  EmployeeDepartmentEnum,
  LeaveApplicationTypeEnum
} from '../../__generated__/schema'
import { RootState } from '../../redux/slices'

export type FormFilterState = {
  [key: string]: boolean
  [EmployeeDepartmentEnum.ACCOUNTING]: boolean
  [EmployeeDepartmentEnum.CS]: boolean
  [EmployeeDepartmentEnum.ENGINEERING]: boolean
  [EmployeeDepartmentEnum.FINANCE]: boolean
  [EmployeeDepartmentEnum.HR]: boolean
  [EmployeeDepartmentEnum.MARKETING]: boolean
  [EmployeeDepartmentEnum.OPERATIONS]: boolean
  [EmployeeDepartmentEnum.SALES]: boolean
  [EmployeeDepartmentEnum.STRATEGY_AND_PLANNING]: boolean
  [EmployeeDepartmentEnum.CS_LEAD]: boolean
  [EmployeeDepartmentEnum.OFFICE_CS]: boolean
  [EmployeeDepartmentEnum.OFFICE_SALES]: boolean
  [EmployeeDepartmentEnum.SALES_SG]: boolean
  [EmployeeDepartmentEnum.SALES_WB]: boolean
  [EmployeeDepartmentEnum.OTHERS]: boolean
  [LeaveApplicationTypeEnum.AL]: boolean
  [LeaveApplicationTypeEnum.SL]: boolean
  [LeaveApplicationTypeEnum.HL]: boolean
  [LeaveApplicationTypeEnum.NPL]: boolean
  [LeaveApplicationTypeEnum.OPL]: boolean
}

export const initialFilterState: FormFilterState = {
  ACCOUNTING: true,
  CS: true,
  ENGINEERING: true,
  FINANCE: true,
  HR: true,
  MARKETING: true,
  OPERATIONS: true,
  SALES: true,
  STRATEGY_AND_PLANNING: true,
  CS_LEAD: true,
  OFFICE_CS: true,
  OFFICE_SALES: true,
  SALES_SG: true,
  SALES_WB: true,
  OTHERS: true,
  AL: true,
  HL: true,
  SL: true,
  WFH: true,
  NPL: true,
  OPL: true
}

export type LeaveConfirmationType =
  | 'APPROVE'
  | 'REJECT'
  | 'DELETE'
  | 'WITHDRAWN'

type State = {
  showLeaveDetailModal: boolean
  showLeaveConfirmationModal: boolean
  showDeleteLeaveFileConfirmation: boolean
  filterState: FormFilterState
  showLeaveDeleteConfirmation: boolean
  showLeaveRejectConfirmation: boolean
  leaveData: LeaveListData | null
  leaveConfirmationType: LeaveConfirmationType | null
  leaveId: string
  fileId: string
  isRefetch: boolean
}

export const initialState: State = {
  filterState: initialFilterState,
  showLeaveDetailModal: false,
  showLeaveConfirmationModal: false,
  leaveData: null,
  leaveConfirmationType: null,
  leaveId: '',
  showLeaveDeleteConfirmation: false,
  showLeaveRejectConfirmation: false,
  showDeleteLeaveFileConfirmation: false,
  fileId: '',
  isRefetch: false
}

const leaveSlice = createSlice({
  name: 'leave',
  initialState,
  reducers: {
    setFilterState(
      state,
      { payload: filterState }: { payload: FormFilterState }
    ) {
      state.filterState = filterState
    },
    setShowLeaveDetailModal(
      state,
      action: PayloadAction<{ showLeaveDetailModal: boolean }>
    ) {
      state.showLeaveDetailModal = action.payload.showLeaveDetailModal
    },
    setShowLeaveConfirmationModal(
      state,
      action: PayloadAction<{
        showLeaveConfirmationModal: boolean
        leaveConfirmationType: LeaveConfirmationType | null
      }>
    ) {
      state.showLeaveConfirmationModal =
        action.payload.showLeaveConfirmationModal
      state.leaveConfirmationType = action.payload.leaveConfirmationType
    },
    setShowLeaveDeleteConfirmation(
      state,
      action: PayloadAction<{ showLeaveDeleteConfirmation: boolean }>
    ) {
      state.showLeaveDeleteConfirmation =
        action.payload.showLeaveDeleteConfirmation
    },
    setShowLeaveRejectConfirmation(
      state,
      action: PayloadAction<{ showLeaveRejectConfirmation: boolean }>
    ) {
      state.showLeaveRejectConfirmation =
        action.payload.showLeaveRejectConfirmation
    },
    setIsRefetch(state, action: PayloadAction<{ isRefetch: boolean }>) {
      state.isRefetch = action.payload.isRefetch
    },
    setLeaveData(
      state,
      action: PayloadAction<{ leaveData: LeaveListData | null }>
    ) {
      state.leaveData = action.payload.leaveData
    },
    setLeaveId(state, action: PayloadAction<{ leaveId: string }>) {
      state.leaveId = action.payload.leaveId
    },
    setShowDeleteLeaveFileConfirmation(
      state,
      action: PayloadAction<{
        showDeleteLeaveFileConfirmation: boolean
        leaveId: string
        fileId: string
      }>
    ) {
      state.fileId = action.payload.fileId
      state.showDeleteLeaveFileConfirmation =
        action.payload.showDeleteLeaveFileConfirmation
      state.leaveId = action.payload.leaveId
    }
  }
})

export const filterStateSelector = (state: RootState) => {
  return state.leaveReducer.filterState
}

export const showLeaveDetailModalSelector = (state: RootState): boolean =>
  state.leaveReducer.showLeaveDetailModal

export const showLeaveConfirmationSelector = (state: RootState): boolean =>
  state.leaveReducer.showLeaveConfirmationModal

export const showLeaveDeleteConfirmationSelector = (
  state: RootState
): boolean => state.leaveReducer.showLeaveDeleteConfirmation

export const showLeaveRejectConfirmationSelector = (
  state: RootState
): boolean => state.leaveReducer.showLeaveRejectConfirmation

export const leaveDataSelector = (state: RootState): LeaveListData | null =>
  state.leaveReducer.leaveData

export const leaveIdSelector = (state: RootState): string =>
  state.leaveReducer.leaveId

export const leaveConfirmationTypeSelector = (
  state: RootState
): LeaveConfirmationType | null => state.leaveReducer.leaveConfirmationType

export const showDeleteLeaveFileConfirmationSelector = (
  state: RootState
): boolean => state.leaveReducer.showDeleteLeaveFileConfirmation

export const fileIdSelector = (state: RootState): string =>
  state.leaveReducer.fileId

export const isRefetchSelector = (state: RootState): boolean => {
  return state.leaveReducer.isRefetch
}

export const { name, actions: leaveActions, reducer } = leaveSlice
